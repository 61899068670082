import { render, staticRenderFns } from "./BaseVisualBackground.vue?vue&type=template&id=54bffadb&scoped=true&"
var script = {}
import style0 from "./BaseVisualBackground.vue?vue&type=style&index=0&id=54bffadb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54bffadb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseMaxWidthContainer: require('/codebuild/output/src718691099/src/threease_lp/components/shared/Base/BaseMaxWidthContainer.vue').default})
