import { render, staticRenderFns } from "./NewsSection.vue?vue&type=template&id=574a5545&scoped=true&"
import script from "./NewsSection.vue?vue&type=script&lang=ts&"
export * from "./NewsSection.vue?vue&type=script&lang=ts&"
import style0 from "./NewsSection.vue?vue&type=style&index=0&id=574a5545&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "574a5545",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseSectionTitle: require('/codebuild/output/src718691099/src/threease_lp/components/shared/Base/BaseSectionTitle.vue').default,BaseImage: require('/codebuild/output/src718691099/src/threease_lp/components/shared/Base/BaseImage.vue').default,NewsList: require('/codebuild/output/src718691099/src/threease_lp/components/features/commonSection/NewsList.vue').default,BaseMaxWidthContainer: require('/codebuild/output/src718691099/src/threease_lp/components/shared/Base/BaseMaxWidthContainer.vue').default})
