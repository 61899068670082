import { render, staticRenderFns } from "./HomePatientsManagmentCard.vue?vue&type=template&id=01d16885&scoped=true&"
import script from "./HomePatientsManagmentCard.vue?vue&type=script&lang=ts&"
export * from "./HomePatientsManagmentCard.vue?vue&type=script&lang=ts&"
import style0 from "./HomePatientsManagmentCard.vue?vue&type=style&index=0&id=01d16885&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01d16885",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseSectionTitle: require('/codebuild/output/src718691099/src/threease_lp/components/shared/Base/BaseSectionTitle.vue').default,BaseImage: require('/codebuild/output/src718691099/src/threease_lp/components/shared/Base/BaseImage.vue').default,BaseMaxWidthContainer: require('/codebuild/output/src718691099/src/threease_lp/components/shared/Base/BaseMaxWidthContainer.vue').default})
