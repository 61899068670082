import { render, staticRenderFns } from "./HomeAbout.vue?vue&type=template&id=5c1064d8&scoped=true&"
import script from "./HomeAbout.vue?vue&type=script&lang=ts&"
export * from "./HomeAbout.vue?vue&type=script&lang=ts&"
import style0 from "./HomeAbout.vue?vue&type=style&index=0&id=5c1064d8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c1064d8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseSectionTitle: require('/codebuild/output/src718691099/src/threease_lp/components/shared/Base/BaseSectionTitle.vue').default,Arrow: require('/codebuild/output/src718691099/src/threease_lp/components/Icons/Arrow.vue').default,BaseMaxWidthContainer: require('/codebuild/output/src718691099/src/threease_lp/components/shared/Base/BaseMaxWidthContainer.vue').default})
