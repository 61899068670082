import { render, staticRenderFns } from "./OurSystemWorks.vue?vue&type=template&id=29fd2c23&scoped=true&"
import script from "./OurSystemWorks.vue?vue&type=script&lang=ts&"
export * from "./OurSystemWorks.vue?vue&type=script&lang=ts&"
import style0 from "./OurSystemWorks.vue?vue&type=style&index=0&id=29fd2c23&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29fd2c23",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseSectionTitle: require('/codebuild/output/src718691099/src/threease_lp/components/shared/Base/BaseSectionTitle.vue').default,BaseMaxWidthContainer: require('/codebuild/output/src718691099/src/threease_lp/components/shared/Base/BaseMaxWidthContainer.vue').default})
