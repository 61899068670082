
import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      clientsList: [
        { img: require('~/assets/clients/sakai.svg') },
        { img: require('~/assets/clients/medical-earth.svg') },
        { img: require('~/assets/clients/KMC.jpg') },
        { img: require('~/assets/clients/HRD.jpg') },
        { img: require('~/assets/clients/wisteria.svg') },
        { img: require('~/assets/clients/therapist_planet.png') },
        { img: require('~/assets/clients/jar-bill.png') },
        { img: require('~/assets/clients/kutsurogi.png') },
        { img: require('~/assets/clients/himawari.png') },
        { img: require('~/assets/clients/bungo.png') },
        { img: require('~/assets/clients/mtag.png') },
        { img: require('~/assets/clients/kagayaki.png') },
        { img: require('~/assets/clients/hitotoki.png') },
        { img: require('~/assets/clients/SMR.png') },
        { img: require('~/assets/clients/FDOC.png') },
        { img: require('~/assets/clients/rokka.png') },
        { img: require('~/assets/clients/movejapan.png') },
        { img: require('~/assets/clients/hapiple-group.png') },
        { img: require('~/assets/clients/yawaragi.png') },
        { img: require('~/assets/clients/onelife.png') },
        { img: require('~/assets/clients/settsu-motoyama.png') },
        { img: require('~/assets/clients/takeda-group.png') },
      ],
    }
  },
})
